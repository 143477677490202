import { TScore, valueSequenceType } from '../../../interfaces/userInterface';
import { jsPDF } from 'jspdf';
import gameDataAPI from '../../../api/gameDataAPI';

const getParsedValueSequence = (data: valueSequenceType[]) => {
  let parsedData = {};
  data.forEach((el) => {
    const scoringInformation = el.scoringInformation;
    parsedData[el.sequenceId._id] = { scoringInformation };
  });
  console.log(parsedData);
  return parsedData;
};

export const sendGameStartMessage = (
  parentOfNext: HTMLDivElement,
  isMuted: boolean,
  data: {
    cohortId: string;
    email: string;
    scoreData: TScore[];
    gameId: string;
    firstname: string;
    lastname: string;
    gender: string;
    parameters: any[];
    valuesScore: any[];
    valuesSequences: any[];
    skip: string[];
    badgeCondition?: number[];
  },
  isFirst: boolean = false
) => {
  // const iframeElement = parentOfNext.firstChild as HTMLIFrameElement;
  const iframeElement: any =
    document.getElementById(data.gameId) || parentOfNext.firstChild;
  console.log('Send start game message', iframeElement);
  console.log('sendGameStartMessage called for ', iframeElement.src);
  iframeElement.contentWindow.postMessage(
    JSON.stringify({
      error: false,
      event: 'gamestart',
      currentid: iframeElement.id,
      isMuted,
      isFirst,
      cohortId: data.cohortId,
      email: data.email,
      scoreData: data.scoreData,
      firstname: data.firstname,
      lastname: data.lastname,
      gender: data.gender,
      valuesSequences: getParsedValueSequence(data.valuesSequences),
      valuesScore: data.valuesScore,
      parameters: data.parameters,
      skip: data.skip,
      valuesGameId: process.env.REACT_APP_VALUES_GAME_ID,
      divisionId: process.env.REACT_APP_DIVISIONID,
      learnerId: localStorage.getItem('learnerId'),
      //!TODO: hardcoding the badge condition for now
      badgeCondition: data.badgeCondition
    }),
    '*'
  );
};

export const createIframeElements = (url, uniqueId) => {
  console.log('Creating iframe elements for url: ', url);

  var container = document.createElement('div');
  container.style.overflow = 'hidden';
  container.style.position = 'absolute';
  container.style.opacity = '0';
  container.style.zIndex = '-1';
  container.style.bottom = '0';
  container.style.right = '0';
  container.style.height = '1px';

  const ifrm = document.createElement('iframe');
  ifrm.setAttribute('src', url);
  ifrm.title = uniqueId;
  ifrm.id = uniqueId;
  ifrm.name = '' + Date.now();
  ifrm.style.display = 'block';
  ifrm.setAttribute('allow', `autoplay 'src';`);
  ifrm.setAttribute('scrolling', `auto`);
  ifrm.style.height = '100%';
  ifrm.style.width = '100%';
  ifrm.style.margin = '0';
  ifrm.style.padding = '0';
  ifrm.style.border = 'none';
  ifrm.style.overflow = 'hidden';

  return {
    container,
    ifrm,
  };
};

export const makeIframeVisible = (parentOfNext: HTMLDivElement, gameId) => {
  const currElement = document.getElementById(gameId);
  if (currElement) {
    parentOfNext = currElement.parentElement as HTMLDivElement;
  }
  parentOfNext.style.width = '100vw';
  parentOfNext.style.height = '100vh';
  parentOfNext.style.opacity = '1';
  parentOfNext.style.zIndex = '1';
  parentOfNext.style.top = '0';
  parentOfNext.style.left = '0';
};


export const downloadImageAsPdf = (imgData: string) => {
  const doc = new jsPDF({ orientation: "landscape" });
  doc.addImage(imgData, 'JPEG', 0, 0, 300, 210);
  doc.save('certificate')

}

export const sendMail = async (text) => {
  const resp = await gameDataAPI.mailer(text);
  // console.log(resp);
  return resp;
}