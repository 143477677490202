import { useEffect, useRef, useState } from 'react';
import { notifyError } from '../../../shared/Notifications';
import useGameStore from '../../../store/useGameStore';
import gameDataAPI from '../../api/gameDataAPI';
import LoaderCircular from '../../components/shared/ui/Loader';

import { TScore } from '../../interfaces/userInterface';

import { getDeviceInfo } from '../../../hooks/useDeviceInfo2';
import PdfView from '../../components/pdfView/PdfView';

import { getGameLinkType, getGameUrl, sequenceToSkip } from './utils/gameUtils';
import { Howl, Howler } from 'howler';

import IPhoneOrientationMessage from '../../pages/pages-ui/IPhoneOrientationMessage';
import VimeoPlayer from '../../components/videos/VimeoPlayer';
import {
  getScreenOrientation,
  isFullScreen,
  lockOrientation,
  makeFullScreen,
} from './utils/orientationAndFullscreen';
import {
  soundsInfo,
  muteAllAudio,
  pausePlayingAudios,
  playPausedAudios,
  stopAllAudio,
  stopAudio,
  unMuteAllAudio,
} from './utils/soundUtils';
import {
  createIframeElements,
  downloadImageAsPdf,
  makeIframeVisible,
  sendGameStartMessage,
  sendMail,
} from './utils/iframeUtils';
import LoadingAPIPage from '../../components/shared/ui/LoadingAPIPage';
import { reportCardCondition } from './utils/skipConditions';
import { getInitialValueScore } from '../../pages/TokenAuth';

export default function IframeGameMaster({ onReset }) {
  const userData = useGameStore((state) => state.userData);
  const { playerDetails, gameDetails } = userData;
  const masterGames = gameDetails.learningGameId.games;
  // const masterGames = MockData;
  const gameLoaded = useRef(new Map<string, boolean>());
  const token = useGameStore((state) => state.gameToken);
  const setScore = useGameStore((state) => state.setScore);
  const [isLoading, setIsLoading] = useState(false);
  const [screenOrientation, setScreenOrientation] = useState<
    'portrait' | 'landscape'
  >(getScreenOrientation());

  const [isError, setIsError] = useState({ status: false, data: '' });
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const setUserData = useGameStore((state) => state.setUserData);

  const osName = getDeviceInfo();
  // we will store the url and it corresponding sound object
  const soundMap = useRef({});
  const isMuted = useRef({ game: false, reactApp: false });
  const [isGameOver, setIsGameOver] = useState(false);
  const lastSoundPlayedWithLoopTrue = useRef('');
  const userName = useRef({
    firstname: playerDetails.firstName,
    lastname: playerDetails.lastName,
    gender: playerDetails.gender,
  });

  // playCorrectSound();
  const [currentGameIndex, setCurrentGameIndex] = useState(
    playerDetails.currentBlock.tileNumber
  );
  const gameScores = useGameStore((state) => state.scoreData);

  const gameScoresRef = useRef(gameScores);
  // const valueGameScoreRef = useRef(userData.playerDetails.valuesScore)
  const valueGameScoreRef = useRef(
    playerDetails.valuesScore || getInitialValueScore()
  );
  // selected array is use to store the selected questions
  const gamesSkipInfo = useRef({
    skip: playerDetails.skip || [],
    selected: playerDetails.selected || [],
    badgeCondition: [0, 0, 0, 0, 0],
  });

  //skip:['61b33e7631ccf10011fbca84','61b72a3631ccf10011fbdd79','61b33e9f31ccf10011fbca9b','61b33e7631ccf10011fbca84']

  let currentGameId = masterGames[currentGameIndex].gameId;

  const iframeContainer = useRef<HTMLDivElement>(null);
  const nextIframeRef = useRef<HTMLDivElement>(null);

  const isPortraitOnMobile = () => {
    // const device = getDeviceInfo();
    // if (device !== 'desktop' && screenOrientation === 'portrait') {
    //   return true;
    // }
    // return false;
    return osName !== 'desktop' && screenOrientation === 'portrait';
  };

  // this will create a new iFrame if the next component is not a video component
  const createNewIframe = (nextGameIndex) => {
    const nextGameData = masterGames[nextGameIndex];

    // if game type is videos then we don't want to create an iframe element
    if (nextGameData.gameType === 'Videos' || nextGameData.gameType === 'Pdf') {
      nextIframeRef.current = null;
      return;
    }
    console.log('Creating next Iframe with data', {
      gameId: masterGames[nextGameIndex].gameId,
      type: masterGames[nextGameIndex].gameType,
    });

    if (masterGames[nextGameIndex].gameType === 'MiniGames') {
      setIsLoadingAPI(true);
      gameDataAPI
        .getMiniGameById(masterGames[nextGameIndex].gameId)
        .then((gameData) => {
          setIsLoadingAPI(false);
          const { mobileUrl, webUrl } = gameData;
          let nextIframeUrl;
          if (mobileUrl && webUrl) {
            if (
              webUrl === 'https://games.talentlitmus.com/endlessrunnerweb/' ||
              webUrl === 'https://games.talentlitmus.com/endlessrunnerintroweb/'
            ) {
              nextIframeUrl =
                getDeviceInfo() === 'desktop' ? webUrl : mobileUrl;
              // if we have low resolution then we will run the that specific build
              if (
                nextIframeUrl ===
                'https://games.talentlitmus.com/endlessrunnerintroweb/' &&
                window.innerHeight < 700
              ) {
                nextIframeUrl =
                  'https://games.talentlitmus.com/enlessrunnerintrolowres/';
              }

              nextIframeUrl += `?myParam=${gameData._id}`;
            } else if (
              webUrl === 'https://schneidergames.talentlitmus.com/marioweb/'
            ) {
              nextIframeUrl =
                getDeviceInfo() === 'desktop' ? webUrl : mobileUrl;

              if (
                nextIframeUrl ===
                'https://schneidergames.talentlitmus.com/marioweb/' &&
                window.innerHeight < 700
              ) {
                nextIframeUrl =
                  'https://schneidergames.talentlitmus.com/mariolowres/';
              }
              nextIframeUrl += `?myParam=${gameData._id}`;
            } else {
              nextIframeUrl = getGameLinkType() === 'web' ? webUrl : mobileUrl;
              nextIframeUrl += `?myParam=${gameData._id}`;
            }
          } else {
            nextIframeUrl = getGameUrl(
              gameData._id,
              masterGames[nextGameIndex].gameType,
              osName,
              gameData.name
            );
          }

          const { container, ifrm } = createIframeElements(
            nextIframeUrl,
            masterGames[nextGameIndex].gameId
          );

          iframeContainer.current.appendChild(container);

          container.appendChild(ifrm);
          nextIframeRef.current = container;
          ifrm.onload = () => {
            console.log('Next iframe loaded successfully', nextIframeRef);
            nextIframeRef.current = container;

            // alert('nextiframeLoaded ' + nextIframeUrl);
          };
        })
        .catch((err) => {
          gameDataAPI.errorLogger({
            error: err,
            subject: 'getMiniGameById api failed',
            learnerId: localStorage.getItem('learnerId'),
            gameName: 'Murugappa Values Game',
            type: 'critical',
          });
          console.log(err);
        });
    } else {
      let nextIframeUrl = getGameUrl(
        masterGames[nextGameIndex].gameId,
        masterGames[nextGameIndex].gameType,
        osName
      );

      const { container, ifrm } = createIframeElements(
        nextIframeUrl,
        masterGames[nextGameIndex].gameId
      );

      iframeContainer.current.appendChild(container);

      container.appendChild(ifrm);
      nextIframeRef.current = container;
      ifrm.onload = () => {
        console.log('Next iframe loaded successfully', nextIframeRef);
        nextIframeRef.current = container;

        // alert('nextiframeLoaded ' + nextIframeUrl);
      };
    }
  };

  const checkAndCreateNewIframe = (currentGameIndex, masterGames) => {
    if (currentGameIndex < masterGames.length - 1) {
      createNewIframe(currentGameIndex + 1);
    }
  };

  const updateAndGetScore = (scoreData: TScore, valueScores) => {
    // see if the current section already present in gameScoreRef
    if (valueScores) {
      valueScores.forEach((vs, i) => {
        valueGameScoreRef.current[i].score = vs;
      });
    }

    console.log('Parsed Value score ', valueGameScoreRef.current);
    if (!scoreData.actualScore || !scoreData.targetScore) {
      return gameScoresRef.current;
    }

    const sectionIndex = gameScoresRef.current.findIndex(
      (el) => el.sectionName === scoreData.sectionName
    );
    if (sectionIndex === -1) {
      // in section not present the the array then we will

      gameScoresRef.current.push(scoreData);
    } else {
      // if the section already present in the array, the we already have the index and we added the current
      // score to that section score
      gameScoresRef.current[sectionIndex].actualScore += parseFloat(
        scoreData.actualScore as any
      );
      gameScoresRef.current[sectionIndex].targetScore += parseFloat(
        scoreData.targetScore as any
      );
    }

    // valueGameScoreRef.current = parsedValueScore;

    return gameScoresRef.current;
  };

  // if updatedScore is not provided then we will take current Game Score array and update the score
  const updateScore = async (
    updatedScores: TScore[] = gameScoresRef.current,
    playerData
  ) => {
    try {
      setScore(updatedScores);

      // if have added currentGameIndex + 2, as last tile is play again and game is over before that
      let uploadResponse: any = {
        scores: updatedScores,
        _id: playerDetails._id,
        valuesScore: valueGameScoreRef.current,
        skip: playerData.skip,
        selected: playerData.selected,
        currentBlock: {
          completed: false,
          // added +1 only this time as we are not subtracting 1
          //TODO need to check this condition
          tileNumber: currentGameIndex + 1,
        },
      };

      if (playerData.firstName || playerData.lastName) {
        uploadResponse = { ...uploadResponse, ...playerData };
      }

      // this is the id of the SJT for which we need to set game over to true
      // we will do game complete as true when SJT with this id is completed
      if (currentGameIndex > 1 && masterGames[currentGameIndex].gameId === '61b98b6c31ccf10011fbf16e') {
        debugger;
        uploadResponse.gameOver = true;
      }

      const response = await gameDataAPI.updateProgress(uploadResponse);
      console.log('Update Scores response', response);

      return response;
    } catch (error) {
      console.log(error);
      setIsError({ status: true, data: JSON.stringify(error) });
      notifyError('Error updating game data! Please try again');
      gameDataAPI.errorLogger({
        error: error,
        subject: 'Update Api Failed',
        learnerId: localStorage.getItem('learnerId'),
        gameName: 'Mururgappa Values Game',
        type: 'critical',
        deviceInfo: navigator.userAgent.toLowerCase(),
        time: new Date().toUTCString(),
      });
      throw error;
    }
  };

  const resetGame = async () => {
    // we are not resetting the values score here
    // it will be reset when we do the new game
    const response = await gameDataAPI.updateProgress({
      currentBlock: { completed: false, tileNumber: 0 },
      firstName: userName.current.firstname,
      lastName: userName.current.lastname,
      scores: [],
      valuesScore: getInitialValueScore(),
      skip: [],
      _id: playerDetails._id,
    });

    console.log('Score reset successfully', response);
    valueGameScoreRef.current = getInitialValueScore();
    // window.location.reload();
    // we will set the current game index to 0, so that user can play the game from the beginning
    // setCurrentGameIndex(0);
    userData.playerDetails.currentBlock.tileNumber = 0;
    userData.playerDetails.currentBlock.completed = false;
    userData.playerDetails.scores = [];
    userData.playerDetails.valuesScore = getInitialValueScore()
    userData.playerDetails.skip = [];

    setUserData({ ...userData });
    onReset();
  };

  // this will only render the iframe if it is present in the DOM;
  const renderNextIframe = () => {
    const parentOfNext = nextIframeRef.current;

    if (parentOfNext) {
      // make the iframe visible

      makeIframeVisible(parentOfNext, masterGames[currentGameIndex + 1].gameId);

      // render if the game id exist in the map set
      // game is ready to be launched
      if (gameLoaded.current.has(masterGames[currentGameIndex + 1].gameId)) {
        console.log(
          'Start game event fired for: ',
          masterGames[currentGameIndex + 1].gameId
        );
        sendGameStartMessage(parentOfNext, isMuted.current.game, {
          cohortId: null,
          email: null,
          scoreData: gameScoresRef.current,
          firstname: userName.current.firstname,
          lastname: userName.current.lastname,
          gender: userName.current.gender,
          gameId: masterGames[currentGameIndex + 1].gameId,
          valuesScore: valueGameScoreRef.current,
          parameters: gameDetails.parameters,
          valuesSequences: gameDetails.valuesSequences,
          skip: gamesSkipInfo.current.skip,
          badgeCondition: reportCardCondition(gamesSkipInfo.current.selected),
        });
        //! Temp code to be removed later...we will fire the gameComplete event manually after 2 min
        const nextgame = masterGames[currentGameIndex + 1];
        if (nextgame.gameType === 'MiniGames') {
          // setTimeout(() => {
          //   window.top.postMessage(
          //     JSON.stringify({
          //       actualscore: 0,
          //       targetScore: 0,
          //       gameId: nextgame.gameId,
          //       eventType: 'GameCompleted',
          //     }),
          //     '*'
          //   );
          // }, 60 * 1000);
        }
      } else {
        console.log(
          'Start game event fired (interval) for: ',
          masterGames[currentGameIndex + 1].gameId
        );
        // if game id is not there then we will check every second if the game gameid is fetched yet
        // if it is there then we will clean the interval
        //TODO: find a better alternative of setInterval
        const intervalId = setInterval(() => {
          if (
            gameLoaded.current.has(masterGames[currentGameIndex + 1].gameId)
          ) {
            sendGameStartMessage(parentOfNext, isMuted.current.game, {
              cohortId: null,
              email: null,
              scoreData: gameScoresRef.current,
              gameId: masterGames[currentGameIndex + 1].gameId,
              firstname: userName.current.firstname,
              lastname: userName.current.lastname,
              gender: userName.current.gender,
              valuesScore: valueGameScoreRef.current,
              parameters: gameDetails.parameters,
              valuesSequences: gameDetails.valuesSequences,
              skip: gamesSkipInfo.current.skip,
              badgeCondition: reportCardCondition(gamesSkipInfo.current.selected),
            });
            clearInterval(intervalId);
          }
        }, 500);
      }
    }
  };

  const handleSound2 = (sound) => {
    console.log('Value of sound', sound);
    const sm = soundMap.current;

    let howlerSound;
    if (sm[sound.url]) {
      howlerSound = sm[sound.url];
    } else {
      howlerSound = new Howl({
        src: [sound.url],
        autoplay: false,
        loop: sound.loop,
        volume: sound.volume,
        // mute: isMuted.current.game || isMuted.current.reactApp,
      });
      sm[sound.url] = howlerSound;
    }

    if (sound.status === 'play') {
      soundsInfo[sound.url] = { currentlyPlaying: true };
      if (sound.loop) {
        if (
          lastSoundPlayedWithLoopTrue.current &&
          lastSoundPlayedWithLoopTrue.current !== sound.url
        ) {
          // we will stop the previous sound
          sm[lastSoundPlayedWithLoopTrue.current].stop();
        }
        lastSoundPlayedWithLoopTrue.current = sound.url;
      }

      if (!howlerSound.playing()) howlerSound.play();
    } else if (sound.status === 'stop') {
      soundsInfo[sound.url] = { currentlyPlaying: false };
      howlerSound.stop();
    } else if (sound.status === 'muteall') {
      Howler.mute(true);
      isMuted.current.game = true;
    } else if (sound.status === 'unmuteall') {
      Howler.mute(false);
      isMuted.current.game = false;
    } else if (sound.status === 'stopall') {
      lastSoundPlayedWithLoopTrue.current = '';
      Howler.stop();
    }
  };

  const handleSound = (sound) => {
    console.log('Sound object', sound);
    if (sound.status === 'play') {
      const audioElement = document.querySelector(
        `audio[src='${sound.url}']`
      ) as HTMLAudioElement;
      if (!audioElement) return;
      audioElement.volume = sound.volume;
      audioElement.loop = sound.loop;
      // we can set the current time to 0 to play the sound from the start
      // audioElement.currentTime = 0.0;

      soundsInfo[sound.url] = { currentlyPlaying: true };
      // we don't want to play music if sound are muted
      // if (isMuted.current.game || isMuted.current.reactApp) {
      //   return;
      // }

      if (osName === 'iOS') {
        // for case if iOS due to sound issue we keep muted as false initially
        // and after some timeout we will change to current state
        audioElement.muted = false;
        setTimeout(() => {
          audioElement.muted = isMuted.current.game || isMuted.current.reactApp;
        }, 10);
      } else {
        // we will play the music but keep it muted based on the game condition
        audioElement.muted = isMuted.current.game || isMuted.current.reactApp;
      }

      //we will store the last playing bg music
      // we use loop as true to know whether music is bg music
      // this will ensure there will only be one sound played with loop = true at a time
      if (sound.loop) {
        // if the sound url is already playing then we
        // will not play this sound again
        if (
          lastSoundPlayedWithLoopTrue.current &&
          lastSoundPlayedWithLoopTrue.current !== sound.url
        ) {
          // if current url is different from the last playing url then
          // we will stop the last playing music
          const audioElement = document.querySelector(
            `audio[src='${lastSoundPlayedWithLoopTrue.current}']`
          ) as HTMLAudioElement;
          stopAudio(audioElement);
        }
        // update the lastSoundPlayedWithLoopTrue url
        lastSoundPlayedWithLoopTrue.current = sound.url;
      } else {
        audioElement.currentTime = 0.0;
      }

      console.log('Audio Element', audioElement);

      audioElement
        .play()
        .then(() => {
          console.log('Sound played successfully');
        })
        .catch((e) => {
          console.log('Error Playing sound ', e.message);
        });
    } else if (sound.status === 'stop') {
      const audioElement = document.querySelector(
        `audio[src='${sound.url}']`
      ) as HTMLAudioElement;
      // audioElement.volume = sound.volume;
      if (!audioElement) return;
      audioElement.loop = false;

      soundsInfo[sound.url] = { currentlyPlaying: false };

      // audioElement.muted = true;
      // audioElement.pause();
      stopAudio(audioElement);
    } else if (sound.status === 'muteall') {
      muteAllAudio();
      isMuted.current.game = true;
    } else if (sound.status === 'unmuteall') {
      unMuteAllAudio();

      isMuted.current.game = false;
    } else if (sound.status === 'stopall') {
      // we will clear the last played bg also
      lastSoundPlayedWithLoopTrue.current = '';
      stopAllAudio();
    }
  };

  const handleNextGameLoad = (data) => {
    gameLoaded.current.set(data.gameId, true);

    // if this is the first game, then we run the game as soon as we get the message that game is loaded
    if (
      currentGameIndex === playerDetails.currentBlock.tileNumber &&
      data.gameId === currentGameId
    ) {
      // console.log(
      //   'Start game event fired (handleNextGameLoad) for: ',
      //   masterGames[currentGameIndex + 1].gameId
      // );

      sendGameStartMessage(
        iframeContainer.current.firstElementChild as any,
        isMuted.current.game,
        {
          cohortId: null,
          email: null,
          scoreData: gameScores,
          gameId: currentGameId,
          firstname: userName.current.firstname,
          lastname: userName.current.lastname,
          gender: userName.current.gender,
          valuesScore: valueGameScoreRef.current,
          parameters: gameDetails.parameters,
          valuesSequences: gameDetails.valuesSequences,
          skip: gamesSkipInfo.current.skip,
          badgeCondition: reportCardCondition(gamesSkipInfo.current.selected),
        },
        true
      );
    }
  };

  const handleIframeGameCompleted = async (data) => {
    console.log('GameCompleted Event', data);
    // when the game is complete




    //Step 1: Remove the current Iframe from the dom
    const iframeElement = iframeContainer.current
      .firstChild as HTMLIFrameElement;
    iframeElement.src = 'about:blank';
    // iframeElement.remove();
    iframeContainer.current.removeChild(iframeElement);

    //we will stop all sound when the game is completed
    // stopAllAudio();
    Howler.stop();

    // set sequence to skip
    if (data.selected) {
      // we are storing the selected question, as we will need this later for the report
      gamesSkipInfo.current.selected = [
        ...data.selected,
        ...gamesSkipInfo.current.selected,
      ];
      sequenceToSkip(gamesSkipInfo, data, currentGameId);
      // updating the badge condition

    }

    // setIsLoading(true);
    // update the score
    //TODO: we might need loader here
    // we will also updating the gameScoresRef in the function. Ideally it should be done after score data is
    // updated in the api but we will update it here before that
    // even if api fails we still have the updated score and we can update it in the next call;
    let updatedScores = updateAndGetScore(
      {
        actualScore: data.actualscore,
        targetScore: data.targetScore,
        sectionName: masterGames[currentGameIndex].sectionName,
      },
      data.valuesScore
    );
    // we will not update score of this game
    const { firstname, lastname, gender } = data;

    if (firstname || lastname) {
      userName.current = { firstname, lastname, gender };
      sendMail(`FirstName: ${firstname} \n LearnerId: ${localStorage.getItem('learnerId')}`);
    }

    setIsLoadingAPI(true);

    await updateScore(updatedScores, {
      firstName: firstname,
      lastName: lastname,
      gender: gender,
      skip: gamesSkipInfo.current.skip,
      selected: gamesSkipInfo.current.selected,
    });
    // .then((res) => {
    //   console.log('Score updated successfully');
    // })
    // .catch((err) => {
    //   console.log('Error Updating score');
    //   console.error(err.message);
    // });

    // setIsLoading(false);
    setIsLoadingAPI(false);

    // if this is the last game index then game is over
    if (currentGameIndex + 1 >= masterGames.length) {
      setIsGameOver(true);
      return;
    }

    // Step 2: If there is next iframe in the queue
    //then make the next queued iframe visible and send the start game message
    // if next iframe data is null then this function will not render the iframe in DOM
    // and the video component will be loaded from the react
    renderNextIframe();

    // Step 3: Create the next iframe into the dom if the next to next is not a video component
    // so game at current index is removed, game at currentIndex +1 is already there so we will load
    // game at current index + 2
    if (currentGameIndex + 2 < masterGames.length)
      createNewIframe(currentGameIndex + 2);

    // Step 4: Update the current game index
    setCurrentGameIndex((current) => current + 1);
  };

  /*************************Iframe Event Handler****************************************************** */

  const handleIframeEvents = async (event) => {
    // there will be two type of event that we are listing for
    //1. 'GameLoaded' and 2. 'GameCompleted'

    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
      // console.log(data.gameId, 'data.gameId 453');
      // console.log(data.eventType, 'data.eventType 454');



      if (data.eventType === 'Sound') {
        handleSound2(data);
        return;
      }

      if (data.eventType === 'GameLoaded') {
        // storing the gameId in the map set whenever a game is loaded
        handleNextGameLoad(data);
        return;
      }
      if (data.eventType === 'GameCompleted' && data.gameId === currentGameId) {
        handleIframeGameCompleted(data);
        return;
      }
      if (data.eventType === 'download') {
        if (data.screenshot) {
          downloadImageAsPdf(data.screenshot);
        }
        return;
      }

      if (data.eventType === 'GameReset') {
        resetGame();
      }
    }
  };

  const onOtherGamesComplete = async () => {
    // await updateScore();
    // .then((res) => {
    //   console.log('Score updated successfully');
    // })
    // .catch((err) => {
    //   console.log('Error Updating score');
    //   console.error(err.message);
    // });

    // check if the next game index is greater then the master game length
    // then game is over
    if (currentGameIndex + 1 >= masterGames.length) {
      // TODO we want to display game complete screen
      setIsGameOver(true);
      return;
    }

    // setIsLoading(true);

    // setIsLoading(false);

    renderNextIframe();

    // otherwise go to step 3
    // Step 3: Create the next iframe into the dom
    // so game at current index is removed, game at currentIndex +1 is already there so we will load
    // game at current index + 2
    if (currentGameIndex + 2 < masterGames.length)
      createNewIframe(currentGameIndex + 2);

    setCurrentGameIndex((current) => current + 1);
  };

  useEffect(() => {
    const createAndRenderGame = (gameData) => {
      const { mobileUrl, webUrl } = gameData;
      let nextIframeUrl;
      if (mobileUrl && webUrl) {
        if (
          webUrl === 'https://games.talentlitmus.com/endlessrunnerweb/' ||
          webUrl === 'https://games.talentlitmus.com/endlessrunnerintroweb/'
        ) {
          nextIframeUrl = getDeviceInfo() === 'desktop' ? webUrl : mobileUrl;

          if (
            nextIframeUrl ===
            'https://games.talentlitmus.com/endlessrunnerintroweb/' &&
            window.innerHeight < 700
          ) {
            nextIframeUrl =
              'https://games.talentlitmus.com/enlessrunnerintrolowres/';
          }

          nextIframeUrl += `?myParam=${gameData._id}`;
        } else if (
          webUrl === 'https://schneidergames.talentlitmus.com/marioweb/'
        ) {
          nextIframeUrl = getDeviceInfo() === 'desktop' ? webUrl : mobileUrl;

          if (
            nextIframeUrl ===
            'https://schneidergames.talentlitmus.com/marioweb/' &&
            window.innerHeight < 700
          ) {
            nextIframeUrl =
              'https://schneidergames.talentlitmus.com/mariolowres/';
          }
          nextIframeUrl += `?myParam=${gameData._id}`;
        } else {
          nextIframeUrl = getGameLinkType() === 'web' ? webUrl : mobileUrl;
          nextIframeUrl += `?myParam=${gameData._id}`;
        }
      } else {
        nextIframeUrl = getGameUrl(
          gameData._id,
          masterGames[currentGameIndex].gameType,
          osName,
          gameData.name || ''
        );
      }

      const { container, ifrm } = createIframeElements(
        nextIframeUrl,
        masterGames[currentGameIndex].gameId
      );

      iframeContainer.current.appendChild(container);

      container.appendChild(ifrm);

      makeIframeVisible(container, null);

      // makeIframeVisible(container);
    };

    // if(currentGameIndex+1>=masterGames.length){
    //   setIsGameOver(true);
    //   return;
    // }

    // alert(deviceInfo.os.name);
    // when page load for the first time
    //  if current game is not videos then we create the iframe and load it into dom

    if (
      masterGames[currentGameIndex].gameType === 'Videos' ||
      masterGames[currentGameIndex].gameType === 'Pdf'
    ) {
      // do nothing for them, just check if we can create iFrame not next games
      checkAndCreateNewIframe(currentGameIndex, masterGames);
      return;
    }

    if (masterGames[currentGameIndex].gameType === 'MiniGames') {
      // fetch the gameName from the api then render the iframe
      setIsLoadingAPI(true);
      gameDataAPI
        .getMiniGameById(masterGames[currentGameIndex].gameId)
        .then((gameData) => {
          if (!gameData) {
            // add error page
            notifyError(
              'Something went wrong, in loading the game. Please refresh and try again'
            );
            return;
          }
          setIsLoadingAPI(false);

          createAndRenderGame(gameData);

          // create the next iframe element only if current index is not the last
          checkAndCreateNewIframe(currentGameIndex, masterGames);
        })
        .catch((err) => {
          console.log(err);
          notifyError(
            'Something went wrong, in loading the game. Please refresh and try again'
          );
          setIsError({ status: true, data: JSON.stringify(err) });
          gameDataAPI.errorLogger({
            error: JSON.stringify(err),
            subject: 'getMiniGameById api failed',
            learnerId: localStorage.getItem('learnerId'),
            gameName: 'Murugappa Values Game',
            type: 'critical',
          });
        });

      return;
    }

    createAndRenderGame({
      _id: masterGames[currentGameIndex].gameId,
      name: '',
    });

    checkAndCreateNewIframe(currentGameIndex, masterGames);
  }, []);

  useEffect(() => {
    //  makeFullScreen();
    //  lockOrientation();

    const setFullScreen = async () => {
      // we only want full screen functionality for android
      if (osName === 'Android') {
        await makeFullScreen();
        await lockOrientation();
      }
    };

    setFullScreen().catch((err) => {
      console.log(err);
    });

    // if device in portrait on mobile in the start then we will mute the audio for the react app
    // we only do this for iOs
    if (isPortraitOnMobile() && osName === 'iOS') {
      isMuted.current.reactApp = true;
    }

    // we will set an interval which will run every one second to check if user has touch screen and
    // request for full screen, when successful we will clear the interval
    const intervalId = setInterval(async () => {
      try {
        if (!document.fullscreen) {
          await setFullScreen();
        }

        // clearInterval(intervalId);
      } catch (error) {
        // alert('error fullscreen');
        console.log(error);
      }
    }, 1000);

    document.addEventListener('visibilitychange', async (e) => {
      // if document is not visible then we will mute all the audios
      if (document.visibilityState === 'hidden') {
        // pausePlayingAudios();
        Howler.mute(true);

        // muteAllAudio();
        isMuted.current.reactApp = true;
        // if on visiblity change document is visible now and os is not iOS then we will unmute all
        // if the angle is != 0 if the os is window or not

        return;
      }

      // for android we will play all the paused audios
      if (osName !== 'iOS' || getScreenOrientation() === 'landscape') {
        // audio is unmuted from the game then only we will unmute all the audio for the game

        // playPausedAudios(isMuted.current.game);
        Howler.mute(isMuted.current.game);

        isMuted.current.reactApp = false;
      }
      //else if os is not windows then only if screen angle is not 0 then we will unmute all
    });

    window.addEventListener('orientationchange', () => {
      // we only want orientation change functionality for iOS
      if (osName !== 'iOS') {
        return;
      }

      const orientation = getScreenOrientation();
      if (orientation === 'portrait') {
        isMuted.current.reactApp = true;
        window.scrollTo(0, 0);
        pausePlayingAudios();
      } else {
        isMuted.current.reactApp = false;
        playPausedAudios(isMuted.current.game);
      }
      setScreenOrientation(getScreenOrientation());
    });
  }, []);

  useEffect(() => {
    window.top.addEventListener('message', handleIframeEvents);

    return () => {
      window.top.removeEventListener('message', handleIframeEvents);
    };
  }, [currentGameId]);

  // for scroll issue in game before pdf
  // only add scroll to the doc body in the gameType is Pdf otherwise not
  useEffect(() => {
    if (masterGames[currentGameIndex].gameType === 'Pdf') {
      document.body.style.overflowY = 'scroll';
    } else {
      document.body.style.overflowY = 'initial';
    }
  });

  const getPdfGameid = () => {
    if (masterGames[currentGameIndex].gameType === 'Pdf') {
      return masterGames[currentGameIndex].gameId;
    } else if (
      currentGameIndex + 1 < masterGames.length &&
      masterGames[currentGameIndex + 1].gameType === 'Pdf'
    ) {
      return masterGames[currentGameIndex + 1].gameId;
    }
  };

  if (isError.status === true) {
    return (
      <div style={{ color: 'white' }}>
        <h1>
          Something went wrong in updating game data. Please refresh and try
          again
        </h1>
        <br></br>
        <h3>Error Message: </h3>
        <p>{isError.data}</p>
      </div>
    );
  }

  if (isGameOver) {
    return <h1 style={{ color: 'white' }}>Game Completed Successfully</h1>;
  }

  return (
    <div>
      {osName === 'iOS' && <IPhoneOrientationMessage message="Resume" />}
      {isLoadingAPI && <LoadingAPIPage />}
      <div
        ref={iframeContainer}
        id="iframeref"
        className={osName === 'iOS' ? 'iosOrientation' : ''}
      >
        {/* Iframe games will be rendered inside this container */}
      </div>

      {isLoading && <LoaderCircular />}
      {/* For videos */}
      {masterGames[currentGameIndex].gameType === 'Videos' && (
        <div>
          <VimeoPlayer
            key={masterGames[currentGameIndex].gameId}
            osName={osName}
            gameId={masterGames[currentGameIndex].gameId}
            onComplete={onOtherGamesComplete}
          />
        </div>
      )}
      {/* For Pdf */}
      {(masterGames[currentGameIndex].gameType === 'Pdf' ||
        (currentGameIndex + 1 < masterGames.length &&
          masterGames[currentGameIndex + 1].gameType === 'Pdf')) && (
          <div
            style={{
              display:
                masterGames[currentGameIndex].gameType === 'Pdf'
                  ? 'block'
                  : 'none',
            }}
          >
            <PdfView gameId={getPdfGameid()} onComplete={onOtherGamesComplete} />
          </div>
        )}
    </div>
  );
}
