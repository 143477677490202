import React, { useEffect, useState } from 'react';
import useGameStore from '../../store/useGameStore';
import gameDataAPI from '../api/gameDataAPI';
import LoadingAPIPage from '../components/shared/ui/LoadingAPIPage';
import CompanyLogo from './CompanyLogo';
import ErrorPage from './pages-ui/TokenError';
import StartScreen from './start-screen/StartScreen';

/* 
gamePrestige
Funds
Team Moral

*/

export const getInitialValueScore = () => [
  {
    index: 1,
    score: 20,
  },
  {
    index: 2,
    score: 70,
  },
  {
    index: 3,
    score: 10,
  },
];

export default function TokenAuth({ children }) {
  // this token is not in use currently
  const token = '80df21359ab5bef96df4';
  const [error, setError] = useState(null);
  const setUserData = useGameStore((state) => state.setUserData);
  const setMusicUrls = useGameStore((state) => state.setMusicUrls);
  const setGameToken = useGameStore((state) => state.setGameToken);
  const user = useGameStore((state) => state.userData);
  const [showLogo, setShowLogo] = useState(true);
  const [isGameOver, setIsGameOver] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowLogo(false);
  //   }, 5 * 1000);
  // }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    gameDataAPI
      .playerLogin()
      .then((userData) => {
        console.log('User data', userData);
        if (
          userData.playerDetails.currentBlock.tileNumber >=
          userData.gameDetails.learningGameId.games.length
        ) {
          // setIsGameOver(true);
        }

        gameDataAPI
          .getMusicAssets(userData.gameDetails.learningGameId._id)
          .then((musicUrls) => {
            console.log(musicUrls);
            setMusicUrls(musicUrls || []);

            // we are setting user data after we have fetched the music urls
            // because without music url we don't want to load the game

            userData.playerDetails.selected = userData.playerDetails.selected.map(
              ({ questionId, selectedOption }) => ({
                questionId,
                selectedOption,
              })
            );

            userData.playerDetails.valuesScore =
              userData.playerDetails.valuesScore.length === 0
                ? userData.gameDetails.parameters.map((p, i) => ({
                    index: p.index,
                    score: 0,
                  }))
                : userData.playerDetails.valuesScore.map(
                    ({ index, score }) => ({ index, score })
                  );

            setUserData(userData);
            setGameToken(token);
          });
      })
      .catch((error) => {
        console.log(error);
        setError(JSON.stringify(error));
      });
  }, []);

  const resetGame = () => {
    // if (playerDetails.currentBlock.tileNumber !== 0) {
    user.playerDetails.currentBlock.tileNumber = 0;
    user.playerDetails.currentBlock.completed = false;
    user.playerDetails.scores = [];
    user.playerDetails.valuesScore = getInitialValueScore();
    user.playerDetails.skip = [];
    user.playerDetails.selected = [];

    // this is optimistic approach, we set the state first the update the api

    setUserData({ ...user });
    setShowStartScreen(false);

    // setIsLoading(true);
    gameDataAPI
      .updateProgress({
        scores: [],
        skip: [],
        selected: [],

        valuesScore: getInitialValueScore(),
        _id: user.playerDetails._id,
        currentBlock: {
          completed: false,
          tileNumber: 0,
        },
      })
      .then((response) => {
        console.log('Update response', response);

        // setGameType(i);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // notifyError('Something went wrong please try again');
        // setIsLoading(false);
        // setShowStartScreen(false);
      });
    // setScore(updatedScores);
    // }else {
    //   setShowStartScreen(false);
    // }
  };

  const onGameStart = (event) => {
    // event 0 is for new game
    // event 1 is for continue game

    if (event === 0 || event === 1) {
      if (event === 0) {
        resetGame();
      } else {
        // play the game
        setShowStartScreen(false);
      }
    }
  };

  if (error) {
    return <ErrorPage text={error} />;
  }
  if (!user) {
    return <LoadingAPIPage />;
  }

  // 0 is for disbaled and 1 is for enabled
  const isContinueBtnDisables =
    user.playerDetails.currentBlock.tileNumber === 0 ||
    user.playerDetails.currentBlock.tileNumber >=
      user.gameDetails.learningGameId.games.length
      ? 0
      : 1;

  if (showLogo && user.gameDetails.learningGameId.companyId.company_logo) {
    return (
      <CompanyLogo
        setShowLogo={setShowLogo}
        logo={user.gameDetails.learningGameId.companyId.company_logo}
      />
    );
  }
  if (showStartScreen) {
    return (
      <StartScreen
        display
        onPreGameCompleted={onGameStart}
        currentTile={isContinueBtnDisables}
      />
    );
  }

  if (isGameOver) {
    return <h1 style={{ color: 'white' }}>Game Completed Successfully</h1>;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
