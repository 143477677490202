import React, { useState } from 'react';
import { enableMapSet } from 'immer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { GlobalContextProvider } from './contexts/GlobalContext';
import { useInitialLoader } from './hooks/useInitialLoader';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './shared/Theme';
import { useDeviceInfo } from './hooks/useDeviceInfo';
import TokenAuth from './app/pages/TokenAuth';
import { useSetFullScreen } from './hooks/useSetFullScreen';
import SoundElements from './app/components/soundElements/SoundElements';
import IframeGameMaster from './app/games/iFrameGameMaster/IframeGameMaster';

enableMapSet();

function App() {
  useInitialLoader();
  useDeviceInfo();
  useSetFullScreen();
  
  const [key, setKey] = useState(0);
  
  const onReset = () => {
    setKey((key) => key + 1);
  };

  return (
    <React.Fragment>
      <GlobalContextProvider>
        <ThemeProvider theme={theme}>
          <div>
            <ToastContainer />
            <TokenAuth>
            <IframeGameMaster onReset={onReset} key={key}/>
            </TokenAuth>
            <SoundElements />
          </div>
        </ThemeProvider>
      </GlobalContextProvider>
    </React.Fragment>
  );
}

export default App;
