import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { getDeviceInfo } from '../../hooks/useDeviceInfo2';

import {
  getGameLinkType,
  getVideoDimensions,
  TimerX,
} from '../games/iFrameGameMaster/utils/gameUtils';
import { getScreenOrientation } from '../games/iFrameGameMaster/utils/orientationAndFullscreen';
import CompanyImage from './../../assets/images/MurugappaTitleImage.png';

import IPhoneOrientationMessage from './pages-ui/IPhoneOrientationMessage';

let { iHeight, iWidth, paddingTop } = getVideoDimensions();

const useStyles = makeStyles({
  container: {
    backgroundColor: 'black',
    display: 'flex',
    // alignItems: 'center',
    paddingTop: paddingTop,
    justifyContent: 'center',
    overflow: 'none',
    width: '100vw',
    height: '100vh',
    // height: '100vh'F
  },
  imgContainer: {
    color: 'white',
    background: 'white',
    height: iHeight,
    width: iWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainerMobile: {
    color: 'white',
    background: 'white',
    height: '100vh',
    width: 'calc(100vh*1.777)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: '50%',
    maxWidth: '100%',
  },

  companyImageContainer: {
    maxWidth: '100%',
    background: `url(${CompanyImage})`,
    color: 'white',
  },
  companyImage: {
    display: 'block',
    maxWidth: '100%',
  },
});

const logoBaseURL = 'https://talent-litmus.s3.ap-south-1.amazonaws.com/';

export default function CompanyLogo({ logo, setShowLogo }) {
  // console.log(logo);
  const [contentToShow, setContentToShow] = useState<'logo' | 'image' | 'end'>(
    'logo'
  );
  const osName = getDeviceInfo();
  const screenOrientation = getScreenOrientation();
  const [showResumeScreen, setShowResumeScreen] = useState(
    osName === 'iOS' && screenOrientation === 'portrait'
  );

  const timerRef = useRef<{first: TimerX, second: TimerX}>({ first: null, second: null });

  useEffect(() => {
    const onOrientationChange = () => {
      const os = getDeviceInfo();
      const o = getScreenOrientation();

      if (os === 'iOS' && o === 'portrait') {
        setShowResumeScreen(true);
        timerRef.current.first.pause(); 
        timerRef.current.second.pause();
        // if (playerRef.current) playerRef.current.pause();
      } else if (osName === 'iOS' && o === 'landscape') {
        setShowResumeScreen(false);
        timerRef.current.first.resume();
        timerRef.current.second.resume();
        // if (playerRef.current) playerRef.current.play();
      }
    };

    window.addEventListener('orientationchange', onOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, []);

  useEffect(() => {
    // after 5 seconds we will set content to show as image

    timerRef.current.first = new TimerX(() => setContentToShow('image'),  5 * 1000);
    timerRef.current.second = new TimerX(() => setContentToShow('end'), 9 * 1000);

    // setTimeout(() => {
    //   setContentToShow('image');
    // }, 5 * 1000);

    // // after 10 second we will set content to show as end, as we want to end the game
    // setTimeout(() => {
    //   setContentToShow('end');
    // }, 9 * 1000);
  }, []);

  useEffect(() => {
    if (contentToShow === 'end') {
      setShowLogo(false);
    }
  }, [contentToShow]);

  const companyLogo = logoBaseURL + logo;
  const gameLinkType = getGameLinkType();

  const classes = useStyles();

  const LogoElement = (
    <div
      className={classes.container}
      style={{
        display: showResumeScreen ? 'none' : 'flex',
      }}
    >
      <div
        className={
          gameLinkType === 'web'
            ? classes.imgContainer
            : classes.imgContainerMobile
        }
      >
        <img
          key="abc"
          className={classes.image}
          src={companyLogo}
          alt="CompanyLogo"
        />
      </div>
    </div>
  );

  const CompanyImageElement = (
    <div
      className={classes.container}
      style={{
        display: showResumeScreen ? 'none' : 'flex',
      }}
    >
      <div
        className={clsx(
          gameLinkType === 'web'
            ? classes.imgContainer
            : classes.imgContainerMobile,
          classes.companyImageContainer
        )}
      >
        <img
          key="pqr"
          className={classes.companyImage}
          src={CompanyImage}
          alt="CompanyLogo"
        />
      </div>
    </div>
  );

  // if (contentToShow === 'logo') {
  //   return LogoElement;
  // }

  return (
    <>
      {osName === 'iOS' && <IPhoneOrientationMessage message='Start' />}

      {contentToShow === 'image' && CompanyImageElement}
      {contentToShow === 'logo' && LogoElement}
    </>
  );
}
