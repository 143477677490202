import React, { useEffect } from 'react';

import useGameStore from '../../../store/useGameStore';
import AfterTileMusic from './../../../assets/sounds/AfterTitleScreen.mp3';

const gameSoundUrls = [
  'https://assets.talentlitmus.com/gamesounds/LightningSound.mp3',
  'https://assets.talentlitmus.com/gamesounds/PointsFinishedSound.mp3',
  'https://assets.talentlitmus.com/gamesounds/PointsTweenSound.mp3',
  'https://assets.talentlitmus.com/gamesounds/jumble-correct.mp3',
  'https://assets.talentlitmus.com/gamesounds/jumble-incorrect.mp3',
  'https://assets.talentlitmus.com/gamesounds/jumblesmatchsjtword-completed.mp3',
  'https://assets.talentlitmus.com/gamesounds/questionClassificationordaringsjtincorrect.mp3',
  'https://assets.talentlitmus.com/gamesounds/questionClassificationordearingsjtcorrect.mp3',
  'https://assets.talentlitmus.com/gamesounds/timer-10-seconds.mp3',
  'https://assets.talentlitmus.com/gamesounds/packing-pack.mp3',
  'https://assets.talentlitmus.com/gamesounds/packing-wrong.mp3',
  // 'https://assets.talentlitmus.com/gamesounds/sneaky-adventure-by-kevin-macleod.mp3',
  'https://assets.talentlitmus.com/gamesounds/rankup.wav',
  'https://assets.talentlitmus.com/gamesounds/PuzzleComplete.mp3',
  'https://assets.talentlitmus.com/gamesounds/PuzzleSwap.mp3',
  'https://assets.talentlitmus.com/gamesounds/PuzzleTap.mp3',
  'https://assets.talentlitmus.com/gamesounds/rankup.wav',
  'https://assets.talentlitmus.com/gamesounds/MMAMbient4-1617521800489.mp3',
  'https://assets.talentlitmus.com/gamesounds/click-blip.mp3',
  'https://assets.talentlitmus.com/gamesounds/thinkingmusic_shortloop+(1).mp3',
  'https://assets.talentlitmus.com/gamesounds/Boar+Sound.mp3',
  'https://assets.talentlitmus.com/gamesounds/Game+Music+.mp3',
  'https://assets.talentlitmus.com/gamesounds/bathroom-closet-door-creak_zk5ogs4O_NWM.mp3',
  'https://assets.talentlitmus.com/gamesounds/366102__original-sound__confirmation-upward+(mp3cut.net).wav',
  'https://assets.talentlitmus.com/gamesounds/leaderboard.mp3',
  'https://assets.talentlitmus.com/gamesounds/AmbientMusic_br.mp3',
  'https://assets.talentlitmus.com/gamesounds/accelerating-spinning-whoosh_fy7LjBV_.mp3',
  'https://assets.talentlitmus.com/gamesounds/playing.mp3',
  'https://assets.talentlitmus.com/gamesounds/mario_over.mp3',
  'https://assets.talentlitmus.com/gamesounds/06-Underground.mp3',
  'https://assets.talentlitmus.com/gamesounds/enemy_killed.mp3',
  'https://assets.talentlitmus.com/gamesounds/mario-jump-sound-effect_1.mp3',
  'https://assets.talentlitmus.com/gamesounds/Mario-coin-sound.mp3',
  'https://assets.talentlitmus.com/gamesounds/collision-sound-effect.mp3',
  'https://assets.talentlitmus.com/gamesounds/upbeat-funk-28-July-2021.mp3',
  'https://assets.talentlitmus.com/gamesounds/mario_feedback_incorrect_28_july_2021.mp3',
  'https://new-cms-assets.s3.ap-south-1.amazonaws.com/gamesounds/mario_theme_fast_05_august_2021.mp3',
  'https://assets.talentlitmus.com/music/Sonyambient1-wav-1639659681193.wav'
];
// test
export default function SoundElements() {
  const musicUrls = useGameStore((state) => state.musicUrls);



  return (
    <div id="audioFiles">
      <audio
        className="JumblesCorrectSound"
        src="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
      ></audio>
      <audio id="aftertitlemusic" src={AfterTileMusic}></audio>

      {gameSoundUrls.map((url, index) => (
        <audio key={index} src={url} muted></audio>
      ))}

      {musicUrls.map((url, index) => (
        <audio key={index} src={url} muted></audio>
      ))}
    </div>
  );
}
