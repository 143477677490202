export const skipConditions = {
  //1. Muru_Air Taxis
  '61b974e331ccf10011fbf083': {
    '0': ['61b9767431ccf10011fbf093', '61b9771831ccf10011fbf099'],
    '1': ['61b9762131ccf10011fbf090', '61b9771831ccf10011fbf099'],
    '2': ['61b9762131ccf10011fbf090', '61b9767431ccf10011fbf093'],
  },

  //2. Muru_Conflict of Interest
  '61b87a5d31ccf10011fbe7d3': {
    '0': ['61b87c7531ccf10011fbe7ec', '61c70380dac010001258ae9a'],
    '1': ['61b87bd231ccf10011fbe7e7', '61c70380dac010001258ae9a'],
    '2': ['61b87bd231ccf10011fbe7e7', '61b87c7531ccf10011fbe7ec'],
  },

  //3. Muru_Construction Debris
  '61b6f4c031ccf10011fbdb33': {
    '0': ['61b6f63131ccf10011fbdb55', '61b6f6b131ccf10011fbdb5c'],
    '1': ['61b6f5ef31ccf10011fbdb4f', '61b6f6b131ccf10011fbdb5c'],
    '2': ['61b6f5ef31ccf10011fbdb4f', '61b6f63131ccf10011fbdb55'],
  },

  //4. Muru_Cricket Finals
  '61b9806e31ccf10011fbf0f9': {
    '0': ['61b981da31ccf10011fbf10b', '61b9819e31ccf10011fbf108'],
    '1': ['61b9827031ccf10011fbf10e', '61b9819e31ccf10011fbf108'],
    '2': ['61b9827031ccf10011fbf10e', '61b981da31ccf10011fbf10b'],
  },

  //5. Muru_Flood Relief
  '61b8819731ccf10011fbe83f': {
    '0': ['61b8849731ccf10011fbe884', '61b884f631ccf10011fbe887'],
    '1': ['61b8845931ccf10011fbe880', '61b884f631ccf10011fbe887'],
    '2': ['61b8845931ccf10011fbe880', '61b8849731ccf10011fbe884'],
  },

  //6. Muru_Game Finances
  '61b6f93d31ccf10011fbdb6a': {
    '0': ['61b6faf531ccf10011fbdb77', '61b6fb3531ccf10011fbdb7a'],
    '1': ['61b6fa8f31ccf10011fbdb74', '61b6fb3531ccf10011fbdb7a'],
    '2': ['61b6fa8f31ccf10011fbdb74', '61b6faf531ccf10011fbdb77'],
  },

  //7. Muru_Hotel Vouchers
  '61b97c1931ccf10011fbf0d0': {
    '0': ['61b97dea31ccf10011fbf0e4', '61b97ea731ccf10011fbf0e9'],
    '1': ['61b97d3a31ccf10011fbf0df', '61b97ea731ccf10011fbf0e9'],
    '2': ['61b97d3a31ccf10011fbf0df', '61b97dea31ccf10011fbf0e4'],
  },

  //8. Muru_Low Air-Resistance
  '61b6f12331ccf10011fbdafb': {
    '0': ['61b6f2b431ccf10011fbdb18', '61b6f30531ccf10011fbdb1b'],
    '1': ['61b6f27831ccf10011fbdb12', '61b6f30531ccf10011fbdb1b'],
    '2': ['61b6f27831ccf10011fbdb12', '61b6f2b431ccf10011fbdb18'],
  },

  //9. Muru_PR Progress
  '61b6ec1c31ccf10011fbda80': {
    '0': ['61b6ed3831ccf10011fbda99', '61b6ed9131ccf10011fbda9d'],
    '1': ['61b6ed0531ccf10011fbda94', '61b6ed9131ccf10011fbda9d'],
    '2': ['61b6ed0531ccf10011fbda94', '61b6ed3831ccf10011fbda99'],
  },

  //10. Muru_Parachuting into Org
  '61b6e45231ccf10011fbd99f': {
    '0': ['61b6e59b31ccf10011fbd9b4', '61b6e5d831ccf10011fbd9b7'],
    '1': ['61b6e54931ccf10011fbd9b1', '61b6e5d831ccf10011fbd9b7'],
    '2': ['61b6e54931ccf10011fbd9b1', '61b6e59b31ccf10011fbd9b4'],
  },

  //11. Muru_Revamping Stadiums ****
  '61b6e7d931ccf10011fbd9f4': {
    '0': [
      '61b8793531ccf10011fbe7c4',
      '61b8798031ccf10011fbe7c7',
      '61b6e99531ccf10011fbda1e',
      '61b6e9eb31ccf10011fbda24',
    ],
    '1': [
      '61b878ff31ccf10011fbe7c2',
      '61b8798031ccf10011fbe7c7',
      '61b6e94f31ccf10011fbda1b',
      '61b6e9eb31ccf10011fbda24',
    ],
    '2': [
      '61b878ff31ccf10011fbe7c2',
      '61b8793531ccf10011fbe7c4',
      '61b6e94f31ccf10011fbda1b',
      '61b6e99531ccf10011fbda1e',
    ],
  },

  //12. Muru_Shooter Misses
  '61b9789431ccf10011fbf0a5': {
    '0': ['61b97a4431ccf10011fbf0bc', '61b97a8931ccf10011fbf0bf'],
    '1': ['61b979e731ccf10011fbf0b9', '61b97a8931ccf10011fbf0bf'],
    '2': ['61b979e731ccf10011fbf0b9', '61b97a4431ccf10011fbf0bc'],
  },

  //13. Muru_Staff Abuse
  '61b8878531ccf10011fbe8b1': {
    '0': ['61b88c5331ccf10011fbe973', '61b88cac31ccf10011fbe97b'],
    '1': ['61b88b7d31ccf10011fbe965', '61b88cac31ccf10011fbe97b'],
    '2': ['61b88b7d31ccf10011fbe965', '61b88c5331ccf10011fbe973'],
  },

  //14. Muru_Top Quality Grass *****
  '61b6fcd631ccf10011fbdb85': {
    '0': ['61b6fdeb31ccf10011fbdb8f', '61d85bc9017cb10014ff867a', '61d84c1888eda300131c5537','61b9728431ccf10011fbf01e'],
    '1': ['61b6fda631ccf10011fbdb8c', '61d85bc9017cb10014ff867a'],
    '2': ['61b6fda631ccf10011fbdb8c', '61b6fdeb31ccf10011fbdb8f', '61d84c1888eda300131c5537','61b9728431ccf10011fbf01e']
 },

  //15. Muru_Unsold Tickets ****
  '61b87d7031ccf10011fbe7f6': {
    '0': [
      '61b87ed031ccf10011fbe821',
      '61b87f3c31ccf10011fbe82d',
      '61b972f931ccf10011fbf052',
      '61b9733831ccf10011fbf07a',
    ],
    '1': [
      '61b87e8731ccf10011fbe812',
      '61b87f3c31ccf10011fbe82d',
      '61b972c331ccf10011fbf023',
      '61b9733831ccf10011fbf07a',
    ],
    '2': [
      '61b87e8731ccf10011fbe812',
      '61b87ed031ccf10011fbe821',
      '61b972c331ccf10011fbf023',
      '61b972f931ccf10011fbf052',
    ],
  },
};

export const skipOnScore = (valueScore: number[]) => {
  let skipSeq = [];
  //85 70 40
  //gamesPrestige
  //funds
  //teamMorale
  const gamesPrestige = valueScore[0];
  const funds = valueScore[1];
  const teamMorale = valueScore[2];

  // team Morale low score
  if (teamMorale >= 50) {
    skipSeq.push('61b9837f31ccf10011fbf11c');
  }
  // funds low score
  if (funds >= 50) {
    skipSeq.push('61b9836031ccf10011fbf11a');
  }
  // game Prestige low score
  if (gamesPrestige >= 50) {
    skipSeq.push('61b9833d31ccf10011fbf118');
  }
  // Highest Score
  if (teamMorale < 80 || funds < 55 || gamesPrestige < 60) {
    skipSeq.push('61b983db31ccf10011fbf120');
  }

  // medium score
  if (
    teamMorale < 50 ||
    funds < 50 ||
    gamesPrestige < 50 ||
    (teamMorale >= 80 &&
      funds >= 55 &&
      gamesPrestige >= 60)
  ) {
    skipSeq.push('61b983b731ccf10011fbf11e');
  }

  return skipSeq;
};

export const reportCardCondition = (
  allSelected: { questionId: string; selectedOption: number[] }[]
) => {
  // five params represent the five badges
  const badgeConditionTemp = [0, 0, 0, 0, 0];

  allSelected.forEach((selected) => {
    const { questionId, selectedOption } = selected;
    // 1. Respect
    if (
      (questionId === '61b6e45231ccf10011fbd99f' && selectedOption[0] === 2) ||
      (questionId === '61b6ec1c31ccf10011fbda80' && selectedOption[0] === 1) ||
      (questionId === '61b8878531ccf10011fbe8b1' && selectedOption[0] === 0)
    ) {
      badgeConditionTemp[0]++;
    }
    // 2. Quality
    if (
      (questionId === '61b6e7d931ccf10011fbd9f4' && selectedOption[0] === 0) ||
      (questionId === '61b6fcd631ccf10011fbdb85' && selectedOption[0] === 1) ||
      (questionId === '61b974e331ccf10011fbf083' && selectedOption[0] === 0)
    ) {
      badgeConditionTemp[1]++;
    }
    //3. Responsibility

    if (
      (questionId === '61b6f4c031ccf10011fbdb33' && selectedOption[0] === 1) ||
      (questionId === '61b87d7031ccf10011fbe7f6' && selectedOption[0] === 2) ||
      (questionId === '61b8819731ccf10011fbe83f' && selectedOption[0] === 2)
    ) {
      badgeConditionTemp[2]++;
    }
    // 4. Integrity
    if (
      (questionId === '61b87a5d31ccf10011fbe7d3' && selectedOption[0] === 0) ||
      (questionId === '61b9789431ccf10011fbf0a5' && selectedOption[0] === 1) ||
      (questionId === '61b97c1931ccf10011fbf0d0' && selectedOption[0] === 0)
    ) {
      badgeConditionTemp[3]++;
    }
    // 5. Passion
    if (
      (questionId === '61b6f12331ccf10011fbdafb' && selectedOption[0] === 2) ||
      (questionId === '61b6f93d31ccf10011fbdb6a' && selectedOption[0] === 2) ||
      (questionId === '61b9806e31ccf10011fbf0f9' && selectedOption[0] === 2)
    ) {
      badgeConditionTemp[4]++;
    }
  });

  console.log('badgeCondition: => ', badgeConditionTemp);

  // 3 means all the three condition are satisfied

  // this we no longer need
  // const badgeCondition = badgeConditionTemp.map((el) => (el === 3 ? 1 : 0));
  return badgeConditionTemp;
};
