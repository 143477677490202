import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getVideoDimensions } from '../../../games/iFrameGameMaster/utils/gameUtils';
import LoaderCircular from './Loader';

const { iHeight, iWidth, paddingTop } = getVideoDimensions();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderContainer: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: paddingTop,
    },

    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: iHeight.toString() + 'px',
      width: iWidth.toString() + 'px',
      backgroundSize: '100% 100% !important',
      // backgroundColor: '#EEEEFF',
      background: 'black',
      color: 'white',

      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

export default function LoadingAPIPage() {
  const classes = useStyles({ type: 2 });

  const [showLoadingImage, setShowLoadingImage] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // after 5 second we will show the trying to reconnect loader
      setShowLoadingImage(false);
    }, 3000);
    
    return () => {
      clearTimeout(timeoutId);
    }
    
  }, []);

  if (showLoadingImage) {
    return <LoaderCircular />;
  }

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loader}>
        <CircularProgress color="primary" size={80} />
        <h1>Trying to connect to the server</h1>
        <p style={{ fontSize: '24px' }}>Please wait...</p>
      </div>      
    </div>
  );
}

/*
  <CircularProgress size="4rem" />
 (props: any) =>
        props.type === 1
          ? `url(${LoaderImageLight})`
          : `url(${LoaderImageDark})`,
 */
