import { getDeviceInfo } from '../../../../hooks/useDeviceInfo2';
import {
  baseGameUrlGeneral,
  baseGameUrlValuesGame,
  SJT_ID_BEFORE_SCORE,
  urlsStart,
} from '../../../constants';
import { skipConditions, skipOnScore } from './skipConditions';

export const getGameLinkType = (): 'mobile' | 'web' => {
  const osName = getDeviceInfo();
  let linkType: 'mobile' | 'web' = 'mobile';
  if (osName === 'Android' || osName === 'iOS' || window.innerHeight <= 768) {
    linkType = 'mobile';
  } else {
    linkType = 'web';
  }

  return linkType;
};

export const getGameUrl = (gameId, gameType, osName, gameName = '') => {
  let linkType = getGameLinkType();
  //TODO: Remove this, temp for now until web version are uploaded
  // linkType = 'mobile';

  if (gameType === 'MiniGames') {
    if (gameName === 'Leaderboard') {
      return `https://games.talentlitmus.com/leaderboard${linkType}?myParam=${gameId}`;
    }

    if (gameName === 'Orientation Packing Game') {
      return `https://demo.talentlitmus.com/orientationpacking${linkType}?myParam=${gameId}`;
    }
    if (gameName === 'Orientation Map Game') {
      return `https://games.talentlitmus.com/mapgame${linkType}?myParam=${gameId}`;
    }
    //TODO handle the case of endless runner - for it we want link type to be of mobile

    // if (gameId === '6062b96d816ac300145a901b') {
    //   //! This is a temp code for testing, we will manually fire game loaded event
    //   window.top.postMessage(
    //     JSON.stringify({
    //       gameId,
    //       eventType: 'GameLoaded',
    //     }),
    //     '*'
    //   ); WheelSpinGame     https://schneidergames.talentlitmus.com/wheelspinweb/

    //   return 'https://demo.talentlitmus.com/endlessrunner3d/';
    // }
  }

  if (gameType === 'SJTGame') {
    return `${baseGameUrlValuesGame}/${urlsStart[gameType]}${linkType}?myParam=${gameId}`;
  }

  return `${baseGameUrlGeneral}/${urlsStart[gameType]}${linkType}?myParam=${gameId}`;
};

export const sequenceToSkip = (
  gameSkipInfoRef: React.MutableRefObject<{
    skip: any[];
  }>,
  gameData,
  currentGameId
) => {
  // const currData = {questionId:gameData.question}
  console.log('Game Data for skip: ', gameData);
  const { selected, valuesScore } = gameData;

  selected.forEach((s) => {
    if (skipConditions[s.questionId]) {
      gameSkipInfoRef.current.skip = gameSkipInfoRef.current.skip.concat(
        skipConditions[s.questionId][s.selectedOption[0]]
      );
    }
  });

  // this is the sequence id just before the feedback screen
  if (currentGameId === SJT_ID_BEFORE_SCORE) {
    const skipArr = skipOnScore(valuesScore);
    gameSkipInfoRef.current.skip = gameSkipInfoRef.current.skip.concat(skipArr);
  }

  console.log(gameSkipInfoRef.current.skip);
};

export const getVideoDimensions = () => {
  let iHeight = window.innerHeight > 720 ? 720 : window.innerHeight;
  let iWidth = window.innerWidth > 1280 ? 1280 : window.innerWidth;
  let paddingTop =
    window.innerWidth > 1280 || window.innerHeight > 720 ? '1.67em' : null;

  const deviceType = getGameLinkType();
  if (deviceType === 'mobile') {
    iHeight = window.innerHeight;
    iWidth = window.innerWidth;
    paddingTop = null;
  }

  return { iHeight, iWidth, paddingTop };
};


export class TimerX {
  private timerId: number;
  private start: number;
  private remaining: number;
  private callback: Function;

  constructor(callback: Function, delay: number) {
    this.callback = callback;
    this.remaining = delay;
    this.resume();
  }

  resume() {
    this.start = Date.now();
    window.clearTimeout(this.timerId);
    this.timerId = window.setTimeout(this.callback, this.remaining);
  }

  pause() {
    window.clearTimeout(this.timerId);
    this.remaining -= Date.now() - this.start;
  }
}
